<template>
	<div class="wrap wrap--login">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
                <h2 class="login-title">로그인</h2>

				<div class="login-container">
					<div class="login-rowgroup">
						<div class="login-row">
							<input v-model="username" class="input" type="email" name="" value="" placeholder="이메일">
						</div>
						<div class="login-row">
							<input v-model="password" class="input" type="password" name="" value="" placeholder="비밀번호" @keydown.enter="login">
						</div>
						<div class="login-row login-row--submit">
							<button @click="login" class="button button--red" type="submit"><span>로그인</span></button>
						</div>
						<div class="login-row login-row--links">
							<label class="check check--primary">
								<input v-model="remember" class="input" type="checkbox" :value="true">
								<i class="icon"></i>
								<span class="text">아이디 저장</span>
							</label>
							<ul class="links">
								<li><a class="link" href="/login/find-id/">아이디 찾기</a></li>
								<li><a class="link" href="/login/find-password/">비밀번호 찾기</a></li>
							</ul>
						</div>
					</div>
					<!-- 회원가입/소셜버튼 -->
					<div class="login-rowgroup login-rowgroup--buttons">
						<div class="login-row">
							<a v-if="$route.query.code === 'sample'" class="button button--border" href="/join?code=sample"><span>회원가입</span></a>
							<a v-else class="button button--border" href="/join"><span>회원가입</span></a>
						</div>
						<div class="login-row" style="display:none;">
							<button class="button button--social-k"><span>카카오 계정으로 가입</span></button>
						</div>
						<div class="login-row" style="display:none;">
							<button class="button button--social-n"><span>네이버 계정으로 가입</span></button>
						</div>
					</div>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";

export default{
	components: {
		MainHeader,
		MainFooter
	},
	data: () => {
		return {
			username: "",
			password: "",

			remember: false
		}
	},
	created() {
	},
	mounted() {
		this.init();
		console.log(this.$route);
	},
	methods : {
		init : function() {
			if(this.$cookies.get('username')) this.username = this.$cookies.get('username');
			if(this.$cookies.get('password')) this.password = CryptoAES.decrypt(this.$cookies.get('password'));
		},
		async login(){
			try{
				await this.$store.dispatch("login", { username: this.username, password: this.password, remember: this.remember });

				if(this.remember){
					this.$cookies.set('username', this.username);
					this.$cookies.set('password', CryptoAES.encrypt(this.password));
				}

				if(this.$route.query.code === 'sample') this.$router.go(-1);
				else this.$router.push('/');
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},
	},
}
</script>
