<template>
	<div class="wrap wrap--login">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
                <h2 class="login-title">비밀번호 찾기</h2>

				<div class="login-container">
					<div class="login-rowgroup">
						<div class="login-row login-row--button">
							<input v-model="username" class="input" type="email" name="" value="" placeholder="이메일">
						</div>
						<div class="login-row login-row--button">
							<div class="select-chip">
								<select v-model="phone1" class="select">
									<option value="010">010</option>
									<option value="011">011</option>
									<option value="016">016</option>
									<option value="017">017</option>
									<option value="019">019</option>
								</select>
								<input v-model="phone2" class="input" type="number" placeholder="- 없이 입력">
							</div>
							<button class="button button--secondary" type="submit" @click="certify"><span>인증번호전송</span></button>
						</div>
						<div class="login-row">
							<input v-model="otp" class="input" type="number" placeholder="인증번호 입력">
						</div>
						<div class="login-row login-row--submit">
							<button class="button button--red" type="submit" @click="recovery"><span>확인</span></button>
						</div>
					</div>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";

import CryptoAES from "@/plugins/crypto-aes";

export default{
	components: {
		MainHeader,
		MainFooter
	},
	data: () => {
		return {
			username: null,
			phone1: "010",
			phone2: null,
			otp: null
		}
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		},

		async certify(){
			try{
				if(!this.username) throw new Error("아이디(이메일)을 입력해주세요");
				if(!this.phone) throw new Error("연락처를 입력해주세요");

				await this.postCertify();

				alert("인증번호가 전송되었습니다");
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},

		async recovery(){
			try{
				if(!this.username) throw new Error("아이디(이메일)을 입력해주세요");
				if(!this.phone) throw new Error("연락처를 입력해주세요");
				if(!this.otp) throw new Error("인증번호를 입력해주세요");

				var password = await this.postRecovery();

				alert(`임시비밀번호[${password}]로 변경되었습니다`);
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},

		postCertify(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.post("/api/v1/users/certify", {
						type: "password",
						username: this.username,
						phone: this.phone.phoneNumberFormat(),
					});

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		},

		postRecovery(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.post("/api/v1/users/recovery", {
						type: "password",
						username: this.username,
						phone: this.phone.phoneNumberFormat(),
						otp: this.otp
					});

					resolve(CryptoAES.decrypt(res.data.password));
				}
				catch(error){
					reject(error);
				}
			});
		}
	},
	computed: {
		phone(){
			return (this.phone1 && this.phone2) ? [this.phone1, this.phone2].join('') : undefined;
		}
	}
}
</script>
